import { TranslatedString } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { LabelWithTooltipMaybeRequired, RenderAddon } from "./InputLine.js";
import { Addon } from "./FormProvider.js";

interface Props {
  label: TranslatedString;
  tooltip?: TranslatedString;
  help?: TranslatedString;
  before?: Addon;
  after?: Addon;
}

export function Caption({ before, after, label, tooltip, help }: Props): VNode {
  return (
    <div class="sm:col-span-6">
      {before !== undefined && <RenderAddon addon={before} />}
      <LabelWithTooltipMaybeRequired label={label} tooltip={tooltip} />
      {after !== undefined && <RenderAddon addon={after} />}
      {help && (
        <p class="mt-2 text-sm text-gray-500" id="email-description">
          {help}
        </p>
      )}
    </div>
  );
}
