/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 * Imports.
 */
import { base64FromArrayBuffer } from "../base64.js";
import { encodeCrock, getRandomBytes, stringToBytes } from "../taler-crypto.js";
import {
  AccessToken,
  LongPollParams,
  PaginationParams,
} from "../types-taler-common.js";

/**
 * Helper function to generate the "Authorization" HTTP header.
 */
export function makeBasicAuthHeader(
  username: string,
  password: string,
): string {
  const auth = `${username}:${password}`;
  const authEncoded: string = base64FromArrayBuffer(stringToBytes(auth));
  return `Basic ${authEncoded}`;
}

/**
 * rfc8959
 * @param token
 * @returns
 */
export function makeBearerTokenAuthHeader(token: AccessToken): string {
  return `Bearer ${token}`;
}

export type BasicOrTokenAuth = BasicAuth | TokenAuth;

export type BasicAuth = {
  type: "basic";
  username: string;
  password: string;
};

export type TokenAuth = {
  type: "bearer";
  token: AccessToken;
};

export function createAuthorizationHeader(auth?: BasicOrTokenAuth): string | undefined {
  if (!auth) return undefined;
  switch (auth.type) {
    case "basic": {
      return makeBasicAuthHeader(auth.username, auth.password);
    }
    case "bearer": {
      return makeBearerTokenAuthHeader(auth.token);
    }
  }
  return undefined;
}

/**
 * https://bugs.gnunet.org/view.php?id=7949
 */
export function addPaginationParams(url: URL, pagination?: PaginationParams) {
  if (!pagination) return;
  if (pagination.offset) {
    url.searchParams.set("offset", pagination.offset);
  }
  const order = !pagination || pagination.order === "asc" ? 1 : -1;
  const limit =
    !pagination || !pagination.limit || pagination.limit === 0
      ? 5
      : Math.abs(pagination.limit);
  //always send limit
  url.searchParams.set("limit", String(order * limit));
}

export function addLongPollingParam(url: URL, param?: LongPollParams) {
  if (!param) return;
  if (param.timeoutMs) {
    url.searchParams.set("timeout_ms", String(param.timeoutMs));
  }
}

export interface CacheEvictor<T> {
  notifySuccess: (op: T) => Promise<void>;
}

export const nullEvictor: CacheEvictor<unknown> = {
  notifySuccess: () => Promise.resolve(),
};

