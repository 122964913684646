/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import {
  Amounts,
  HttpStatusCode,
  TalerError,
  assertUnreachable,
} from "@gnu-taler/taler-util";
import {
  Loading,
  notifyInfo,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { ErrorLoadingWithDebug } from "../components/ErrorLoadingWithDebug.js";
import { useAccountDetails } from "../hooks/account.js";
import { useSessionState } from "../hooks/session.js";
import { LoginForm } from "./LoginForm.js";
import { PaytoWireTransferForm } from "./PaytoWireTransferForm.js";
import { RouteDefinition } from "@gnu-taler/web-util/browser";

export function WireTransfer({
  toAccount,
  withSubject,
  withAmount,
  onAuthorizationRequired,
  routeCancel,
  routeHere,
  onSuccess,
}: {
  onSuccess?: () => void;
  routeHere: RouteDefinition<{
    account?: string;
    subject?: string;
    amount?: string;
  }>;
  toAccount?: string;
  withSubject?: string;
  withAmount?: string;
  routeCancel?: RouteDefinition;
  onAuthorizationRequired: () => void;
}): VNode {
  const { i18n } = useTranslationContext();
  const r = useSessionState();
  const account = r.state.status !== "loggedOut" ? r.state.username : "admin";
  const result = useAccountDetails(account);

  if (!result) {
    return <Loading />;
  }
  if (result instanceof TalerError) {
    return (
      <Fragment>
        <ErrorLoadingWithDebug error={result} />
        <LoginForm currentUser={account} />
      </Fragment>
    );
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.Unauthorized:
        return <LoginForm currentUser={account} />;
      case HttpStatusCode.NotFound:
        return <LoginForm currentUser={account} />;
      default:
        assertUnreachable(result);
    }
  }
  const { body: data } = result;

  const balance = Amounts.parseOrThrow(data.balance.amount);
  const balanceIsDebit = data.balance.credit_debit_indicator == "debit";

  const debitThreshold = Amounts.parseOrThrow(data.debit_threshold);

  if (!balance) return <Fragment />;

  const limit = balanceIsDebit
    ? Amounts.sub(debitThreshold, balance).amount
    : Amounts.add(balance, debitThreshold).amount;

  const positiveBalance = balanceIsDebit
    ? Amounts.zeroOfAmount(balance)
    : balance;
  return (
    <div class="px-4 mt-8">
      <div class="sm:flex sm:items-center mb-4">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">
            <i18n.Translate>Make a wire transfer</i18n.Translate>
          </h1>
        </div>
      </div>

      <PaytoWireTransferForm
        withAccount={toAccount}
        withAmount={withAmount}
        balance={positiveBalance}
        withSubject={withSubject}
        routeHere={routeHere}
        limit={limit}
        onAuthorizationRequired={onAuthorizationRequired}
        onSuccess={() => {
          notifyInfo(i18n.str`The wire transfer was successfully completed!`);
          if (onSuccess) onSuccess();
        }}
        routeCancel={routeCancel}
      />
    </div>
  );
}
