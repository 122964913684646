/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { AmountJson, TalerError } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useEffect } from "preact/hooks";
import { useWithdrawalDetails } from "../hooks/account.js";
import { useBankState } from "../hooks/bank-state.js";
import { useSessionState } from "../hooks/session.js";
import { RouteDefinition } from "@gnu-taler/web-util/browser";
import { PaytoWireTransferForm } from "./PaytoWireTransferForm.js";
import { WalletWithdrawForm } from "./WalletWithdrawForm.js";

function ShowOperationPendingTag({
  woid,
  onOperationAlreadyCompleted,
}: {
  woid: string;
  onOperationAlreadyCompleted?: () => void;
}): VNode {
  const { i18n } = useTranslationContext();
  const { state: credentials } = useSessionState();
  const result = useWithdrawalDetails(woid);
  const loading = !result;
  const error =
    !loading && (result instanceof TalerError || result.type === "fail");
  const pending =
    !loading &&
    !error &&
    result.body.status === "selected" &&
    // (result.body.status === "pending" || result.body.status === "selected") &&
    credentials.status === "loggedIn" &&
    credentials.username === result.body.username;

  if (error || !pending) {
    return <Fragment />;
  }

  return (
    <span class="flex items-center gap-x-1.5 w-fit rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700 whitespace-pre">
      <svg
        class="h-1.5 w-1.5 fill-yellow-500"
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx="3" cy="3" r="3" />
      </svg>
      <i18n.Translate>Pending operation</i18n.Translate>
    </span>
  );
}

/**
 * Let the user choose a payment option,
 * then specify the details trigger the action.
 */
export function PaymentOptions({
  routeClose,
  routeCashout,
  routeChargeWallet,
  routeWireTransfer,
  tab,
  limit,
  balance,
  onOperationCreated,
  onClose,
  routeOperationDetails,
  onAuthorizationRequired,
}: {
  limit: AmountJson;
  balance: AmountJson;
  tab: "charge-wallet" | "wire-transfer" | undefined;
  onAuthorizationRequired: () => void;
  onOperationCreated: (wopid: string) => void;
  onClose: () => void;

  routeOperationDetails: RouteDefinition<{ wopid: string }>;
  routeClose: RouteDefinition;
  routeCashout: RouteDefinition;
  routeChargeWallet: RouteDefinition;
  routeWireTransfer: RouteDefinition<{
    account?: string;
    subject?: string;
    amount?: string;
  }>;
}): VNode {
  const { i18n } = useTranslationContext();
  const [bankState, updateBankState] = useBankState();

  return (
    <div class="mt-4">
      <fieldset>
        <legend class="px-4 text-base font-semibold leading-6 text-gray-900">
          <i18n.Translate>Send money</i18n.Translate>
        </legend>

        <div class="px-4 mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          {/* <!-- Active: "border-indigo-600 ring-2 ring-indigo-600", Not Active: "border-gray-300" --> */}
          <a name="charge wallet" href={routeChargeWallet.url({})}>
            <label
              class={
                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none" +
                (tab === "charge-wallet"
                  ? "border-indigo-600 ring-2 ring-indigo-600"
                  : "border-gray-300")
              }
            >
              <div class="flex flex-col">
                <span class="flex">
                  <div class="text-4xl mr-4 my-auto">&#x1F4B5;</div>
                  <span class="grow self-center text-lg text-gray-900 align-middle text-center">
                    <i18n.Translate>to a Taler wallet</i18n.Translate>
                  </span>
                  <svg
                    class="self-center flex-none h-5 w-5 text-indigo-600"
                    style={{
                      visibility:
                        tab === "charge-wallet" ? "visible" : "hidden",
                    }}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <div class="mt-1 flex items-center text-sm text-gray-500">
                  <i18n.Translate>
                    Withdraw digital money into your mobile wallet or browser
                    extension
                  </i18n.Translate>
                </div>
                {!!bankState.currentWithdrawalOperationId && (
                  <ShowOperationPendingTag
                    woid={bankState.currentWithdrawalOperationId}
                    onOperationAlreadyCompleted={() => {
                      updateBankState(
                        "currentWithdrawalOperationId",
                        undefined,
                      );
                    }}
                  />
                )}
              </div>
            </label>
          </a>

          <a name="wire transfer" href={routeWireTransfer.url({})}>
            <label
              class={
                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none" +
                (tab === "wire-transfer"
                  ? "border-indigo-600 ring-2 ring-indigo-600"
                  : "border-gray-300")
              }
            >
              <div class="flex flex-col">
                <span class="flex">
                  <div class="text-4xl mr-4 my-auto">&#x2194;</div>
                  <span class="grow self-center text-lg font-medium text-gray-900 align-middle text-center">
                    <i18n.Translate>to another bank account</i18n.Translate>
                  </span>
                  <svg
                    class="self-center flex-none h-5 w-5 text-indigo-600"
                    style={{
                      visibility:
                        tab === "wire-transfer" ? "visible" : "hidden",
                    }}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <div class="mt-1 flex items-center text-sm text-gray-500">
                  <i18n.Translate>
                    Make a wire transfer to an account with known bank account
                    number.
                  </i18n.Translate>
                </div>
              </div>
            </label>
          </a>
        </div>
        {tab === "charge-wallet" && (
          <WalletWithdrawForm
            routeOperationDetails={routeOperationDetails}
            focus
            limit={limit}
            balance={balance}
            onAuthorizationRequired={onAuthorizationRequired}
            onOperationCreated={onOperationCreated}
            onOperationAborted={onClose}
            routeCancel={routeClose}
          />
        )}
        {tab === "wire-transfer" && (
          <PaytoWireTransferForm
            focus
            routeHere={routeWireTransfer}
            limit={limit}
            balance={balance}
            onAuthorizationRequired={onAuthorizationRequired}
            onSuccess={onClose}
            routeCashout={routeCashout}
            routeCancel={routeClose}
          />
        )}
      </fieldset>
    </div>
  );
}
