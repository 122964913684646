import { TranslatedString } from "@gnu-taler/taler-util";
import { Fragment, VNode, h } from "preact";
import {
  UIFormElementConfig,
  getConverterById,
  useTranslationContext,
} from "../index.browser.js";
import { FormProvider, FormProviderProps, FormState } from "./FormProvider.js";
import {
  RenderAllFieldsByUiConfig,
  UIFormField,
  convertUiField,
} from "./forms.js";
// import { FlexibleForm } from "./ui-form.js";

/**
 * Flexible form uses a DoubleColumForm for design
 * and may have a dynamic properties defined by
 * behavior function.
 */
export interface FlexibleForm_Deprecated<T extends object> {
  design: DoubleColumnForm_Deprecated;
  behavior?: (form: Partial<T>) => FormState<T>;
}

/**
 * Double column form
 *
 * Form with sections, every sections have a title and may
 * have a description.
 * Every sections contain a set of fields.
 */
export type DoubleColumnForm_Deprecated = Array<
  DoubleColumnFormSection_Deprecated | undefined
>;

export type DoubleColumnFormSection_Deprecated = {
  title: TranslatedString;
  description?: TranslatedString;
  fields: UIFormElementConfig[];
};

/**
 * Form Provider implementation that use FlexibleForm
 * to defined behavior and fields.
 */
export function DefaultForm<T extends object>({
  initial,
  onUpdate,
  form,
  onSubmit,
  children,
  readOnly,
}: Omit<FormProviderProps<T>, "computeFormState"> & {
  form: FlexibleForm_Deprecated<T>;
}): VNode {
  const { i18n } = useTranslationContext();
  return (
    <FormProvider
      initial={initial}
      onUpdate={onUpdate}
      onSubmit={onSubmit}
      readOnly={readOnly}
    >
      <div class="space-y-10 divide-y -mt-5 divide-gray-900/10">
        {form.design.map((section, i) => {
          if (!section) return <Fragment />;
          return (
            <div
              key={i}
              class="grid grid-cols-1 gap-x-8 gap-y-8 pt-5 md:grid-cols-3"
            >
              <div class="px-4 sm:px-0">
                <h2 class="text-base font-semibold leading-7 text-gray-900">
                  {section.title}
                </h2>
                {section.description && (
                  <p class="mt-1 text-sm leading-6 text-gray-600">
                    {section.description}
                  </p>
                )}
              </div>
              <div class="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-md md:col-span-2">
                <div class="p-3">
                  <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <RenderAllFieldsByUiConfig
                      key={i}
                      fields={convertUiField(
                        i18n,
                        section.fields,
                        form,
                        getConverterById,
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {children}
    </FormProvider>
  );
}
