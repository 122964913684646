/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { TranslatedString } from "@gnu-taler/taler-util";
import { Attention, useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { Transactions } from "../../components/Transactions/index.js";
import { useBankState } from "../../hooks/bank-state.js";
import { usePreferences } from "../../hooks/preferences.js";
import { PaymentOptions } from "../PaymentOptions.js";
import { State } from "./index.js";
import { RouteDefinition } from "@gnu-taler/web-util/browser";

export function InvalidIbanView({ error }: State.InvalidIban) {
  return (
    <div>Payto from server is not valid &quot;{error.payto_uri}&quot;</div>
  );
}

const IS_PUBLIC_ACCOUNT_ENABLED = false;

function ShowDemoInfo({
  routePublicAccounts,
}: {
  routePublicAccounts: RouteDefinition;
}): VNode {
  const { i18n } = useTranslationContext();
  const [settings, updateSettings] = usePreferences();
  if (!settings.showDemoDescription) return <Fragment />;
  return (
    <Attention
      title={i18n.str`This is a demo`}
      onClose={() => {
        updateSettings("showDemoDescription", false);
      }}
    >
      {IS_PUBLIC_ACCOUNT_ENABLED ? (
        <i18n.Translate>
          This part of the demo shows how a bank that supports Taler directly
          would work. In addition to using your own bank account, you can also
          see the transaction history of some{" "}
          <a name="public account" href={routePublicAccounts.url({})}>
            Public Accounts
          </a>
          .
        </i18n.Translate>
      ) : (
        <i18n.Translate>
          Here you will be able to see how a bank that supports Taler directly
          would work.
        </i18n.Translate>
      )}
    </Attention>
  );
}

function ShowPedingOperation({
  routeSolveSecondFactor,
}: {
  routeSolveSecondFactor: RouteDefinition;
}): VNode {
  const { i18n } = useTranslationContext();
  const [bankState, updateBankState] = useBankState();
  if (!bankState.currentChallenge) return <Fragment />;
  const title = ((op): TranslatedString => {
    switch (op) {
      case "delete-account":
        return i18n.str`Pending account delete operation`;
      case "update-account":
        return i18n.str`Pending account update operation`;
      case "update-password":
        return i18n.str`Pending password update operation`;
      case "create-transaction":
        return i18n.str`Pending transaction operation`;
      case "confirm-withdrawal":
        return i18n.str`Pending withdrawal operation`;
      case "create-cashout":
        return i18n.str`Pending cashout operation`;
    }
  })(bankState.currentChallenge.operation);
  return (
    <Attention
      title={title}
      type="warning"
      onClose={() => {
        updateBankState("currentChallenge", undefined);
      }}
    >
      <i18n.Translate>
        You can complete or cancel the operation in
      </i18n.Translate>{" "}
      <a
        class="font-semibold text-yellow-700 hover:text-yellow-600"
        name="complete operation"
        href={routeSolveSecondFactor.url({})}
      >
        <i18n.Translate>this page</i18n.Translate>
      </a>
    </Attention>
  );
}

export function ReadyView({
  tab,
  account,
  routeChargeWallet,
  routeWireTransfer,
  limit,
  balance,
  routeCashout,
  routeCreateWireTransfer,
  routePublicAccounts,
  routeOperationDetails,
  routeSolveSecondFactor,
  onClose,
  routeClose,
  onOperationCreated,
  onAuthorizationRequired,
}: State.Ready): VNode {
  return (
    <Fragment>
      <ShowPedingOperation routeSolveSecondFactor={routeSolveSecondFactor} />
      <ShowDemoInfo routePublicAccounts={routePublicAccounts} />
      <PaymentOptions
        tab={tab}
        routeOperationDetails={routeOperationDetails}
        routeCashout={routeCashout}
        routeChargeWallet={routeChargeWallet}
        routeWireTransfer={routeWireTransfer}
        limit={limit}
        balance={balance}
        routeClose={routeClose}
        onClose={onClose}
        onOperationCreated={onOperationCreated}
        onAuthorizationRequired={onAuthorizationRequired}
      />
      <Transactions
        account={account}
        routeCreateWireTransfer={routeCreateWireTransfer}
      />
    </Fragment>
  );
}
