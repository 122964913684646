/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  AbsoluteTime,
  AmountJson,
  TalerCorebankApi,
  TalerError,
} from "@gnu-taler/taler-util";
import { Loading, utils } from "@gnu-taler/web-util/browser";
import { ErrorLoadingWithDebug } from "../../components/ErrorLoadingWithDebug.js";
import { LoginForm } from "../LoginForm.js";
import { useComponentState } from "./state.js";
import { InvalidIbanView, ReadyView } from "./views.js";
import { RouteDefinition } from "@gnu-taler/web-util/browser";
import { Fragment } from "preact";

export interface Props {
  account: string;
  onAuthorizationRequired: () => void;
  onOperationCreated: (wopid: string) => void;
  onClose: () => void;
  tab: "charge-wallet" | "wire-transfer" | undefined;
  routeClose: RouteDefinition;
  routeCashout: RouteDefinition;
  routeChargeWallet: RouteDefinition;
  routeWireTransfer: RouteDefinition<{
    account?: string;
    subject?: string;
    amount?: string;
  }>;
  routePublicAccounts: RouteDefinition;
  routeCreateWireTransfer: RouteDefinition<{
    account?: string;
    subject?: string;
    amount?: string;
  }>;
  routeOperationDetails: RouteDefinition<{ wopid: string }>;
  routeSolveSecondFactor: RouteDefinition;
}

export type State =
  | State.Loading
  | State.LoadingError
  | State.Ready
  | State.InvalidIban
  | State.UserNotFound;

export namespace State {
  export interface Loading {
    status: "loading";
    error: undefined;
  }

  export interface LoadingError {
    status: "loading-error";
    error: TalerError;
  }

  export interface BaseInfo {
    error: undefined;
  }

  export interface Ready extends BaseInfo {
    status: "ready";
    error: undefined;
    account: string;
    tab: "charge-wallet" | "wire-transfer" | undefined;
    limit: AmountJson;
    balance: AmountJson;
    onAuthorizationRequired: () => void;
    onOperationCreated: (wopid: string) => void;
    onClose: () => void;
    routeClose: RouteDefinition;
    routeCashout: RouteDefinition;
    routeChargeWallet: RouteDefinition;
    routePublicAccounts: RouteDefinition;
    routeWireTransfer: RouteDefinition<{
      account?: string;
      subject?: string;
      amount?: string;
    }>;
    routeCreateWireTransfer: RouteDefinition<{
      account?: string;
      subject?: string;
      amount?: string;
    }>;
    routeOperationDetails: RouteDefinition<{ wopid: string }>;
    routeSolveSecondFactor: RouteDefinition;
  }

  export interface InvalidIban {
    status: "invalid-iban";
    error: TalerCorebankApi.AccountData;
  }

  export interface UserNotFound {
    status: "login";
    reason: "not-found" | "forbidden";
    routeRegister?: RouteDefinition;
  }
}

export interface Transaction {
  negative: boolean;
  counterpart: string;
  when: AbsoluteTime;
  amount: AmountJson | undefined;
  subject: string;
}

const viewMapping: utils.StateViewMap<State> = {
  loading: Loading,
  login: LoginForm,
  "invalid-iban": InvalidIbanView,
  "loading-error": (d) => {
    return Fragment({
      children: [ErrorLoadingWithDebug({ error: d.error }), LoginForm({})],
    })!;
  },
  ready: ReadyView,
};

export const AccountPage = utils.compose(
  (p: Props) => useComponentState(p),
  viewMapping,
);
