/*
 This file is part of GNU Taler
 (C) 2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU Affero General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU Affero General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>

 SPDX-License-Identifier: AGPL-3.0-or-later
 */

/**
 * Imports.
 */
import { HttpRequestLibrary, readTalerErrorResponse } from "../http-common.js";
import { HttpStatusCode } from "../http-status-codes.js";
import { createPlatformHttpLib } from "../http.js";
import { LibtoolVersion } from "../libtool-version.js";
import {
  FailCasesByMethod,
  ResultByMethod,
  opKnownAlternativeFailure,
  opKnownHttpFailure,
  opSuccessFromHttp,
  opUnknownFailure,
} from "../operation.js";
import {
  codecForChallengeInvalidPinResponse,
  codecForChallengeResponse,
  codecForChallengeSetupResponse,
  codecForChallengeSolveResponse,
  codecForChallengeStatus,
  codecForChallengerAuthResponse,
  codecForChallengerInfoResponse,
  codecForChallengerTermsOfServiceResponse,
} from "../types-taler-challenger.js";
import { AccessToken } from "../types-taler-common.js";
import {
  CacheEvictor,
  makeBearerTokenAuthHeader,
  nullEvictor,
} from "./utils.js";

export type ChallengerResultByMethod<prop extends keyof ChallengerHttpClient> =
  ResultByMethod<ChallengerHttpClient, prop>;
export type ChallengerErrorsByMethod<prop extends keyof ChallengerHttpClient> =
  FailCasesByMethod<ChallengerHttpClient, prop>;

export enum ChallengerCacheEviction {
  CREATE_CHALLENGE,
  SOLVE_CHALLENGE,
}

/**
 */
export class ChallengerHttpClient {
  httpLib: HttpRequestLibrary;
  cacheEvictor: CacheEvictor<ChallengerCacheEviction>;
  public readonly PROTOCOL_VERSION = "2:0:0";

  constructor(
    readonly baseUrl: string,
    httpClient?: HttpRequestLibrary,
    cacheEvictor?: CacheEvictor<ChallengerCacheEviction>,
  ) {
    this.httpLib = httpClient ?? createPlatformHttpLib();
    this.cacheEvictor = cacheEvictor ?? nullEvictor;
  }

  isCompatible(version: string): boolean {
    const compare = LibtoolVersion.compare(this.PROTOCOL_VERSION, version);
    return compare?.compatible ?? false;
  }
  /**
   * https://docs.taler.net/core/api-challenger.html#get--config
   *
   */
  async getConfig() {
    const url = new URL(`config`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(
          resp,
          codecForChallengerTermsOfServiceResponse(),
        );
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }
  /**
   * https://docs.taler.net/core/api-challenger.html#post--setup-$CLIENT_ID
   *
   */
  async setup(clientId: string, token: AccessToken) {
    const url = new URL(`setup/${clientId}`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
      headers: {
        Authorization: makeBearerTokenAuthHeader(token),
      },
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForChallengeSetupResponse());
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  // LOGIN

  /**
   * https://docs.taler.net/core/api-challenger.html#post--authorize-$NONCE
   *
   */
  async login(
    nonce: string,
    clientId: string,
    redirectUri: string,
    state: string | undefined,
  ) {
    const url = new URL(`authorize/${nonce}`, this.baseUrl);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("client_id", clientId);
    url.searchParams.set("redirect_uri", redirectUri);
    if (state) {
      url.searchParams.set("state", state);
    }
    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForChallengeStatus());
      case HttpStatusCode.BadRequest:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotAcceptable:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.TooManyRequests:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.InternalServerError:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  // CHALLENGE

  /**
   * https://docs.taler.net/core/api-challenger.html#post--challenge-$NONCE
   *
   */
  async challenge(nonce: string, body: Record<string, string>) {
    const url = new URL(`challenge/${nonce}`, this.baseUrl);

    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
      body: new URLSearchParams(Object.entries(body)).toString(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "manual",
    });
    switch (resp.status) {
      case HttpStatusCode.Ok: {
        await this.cacheEvictor.notifySuccess(
          ChallengerCacheEviction.CREATE_CHALLENGE,
        );
        return opSuccessFromHttp(resp, codecForChallengeResponse());
      }
      case HttpStatusCode.BadRequest:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotAcceptable:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.TooManyRequests:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.InternalServerError:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  // SOLVE

  /**
   * https://docs.taler.net/core/api-challenger.html#post--solve-$NONCE
   *
   */
  async solve(nonce: string, body: Record<string, string>) {
    const url = new URL(`solve/${nonce}`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
      body: new URLSearchParams(Object.entries(body)).toString(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "manual",
    });
    switch (resp.status) {
      case HttpStatusCode.Ok: {
        await this.cacheEvictor.notifySuccess(
          ChallengerCacheEviction.SOLVE_CHALLENGE,
        );
        return opSuccessFromHttp(resp, codecForChallengeSolveResponse());
      }
      case HttpStatusCode.BadRequest:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.Forbidden:
        return opKnownAlternativeFailure(
          resp,
          HttpStatusCode.Forbidden,
          codecForChallengeInvalidPinResponse(),
        );
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotAcceptable:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.TooManyRequests:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.InternalServerError:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  // AUTH

  /**
   * https://docs.taler.net/core/api-challenger.html#post--token
   *
   */
  async token(
    client_id: string,
    redirect_uri: string,
    client_secret: AccessToken,
    code: string,
  ) {
    const url = new URL(`token`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(
        Object.entries({
          client_id,
          redirect_uri,
          client_secret,
          code,
          grant_type: "authorization_code",
        }),
      ).toString(),
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForChallengerAuthResponse());
      case HttpStatusCode.Forbidden:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }

  // INFO

  /**
   * https://docs.taler.net/core/api-challenger.html#get--info
   *
   */
  async info(token: AccessToken) {
    const url = new URL(`info`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: makeBearerTokenAuthHeader(token),
      },
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForChallengerInfoResponse());
      case HttpStatusCode.Forbidden:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }
}
