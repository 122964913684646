/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { Time, useNotifications } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";

export function ShowNotifications(): VNode {
  const ns = useNotifications();
  if (!ns.length) {
    return <div>no notifications</div>;
  }
  return (
    <div>
      <p>Notifications</p>
      <table>
        <thead></thead>
        <tbody>
          {ns.map((n, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <Time
                    timestamp={n.message.when}
                    format="dd/MM/yyyy HH:mm:ss"
                  />
                </td>
                <td>{n.message.title}</td>
                <td>
                  {n.message.type === "error"
                    ? n.message.description
                    : undefined}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <ToastBanner all /> */}
    </div>
  );
}
