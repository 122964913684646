/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { AbsoluteTime, AmountJson, TalerError } from "@gnu-taler/taler-util";
import { Loading, utils } from "@gnu-taler/web-util/browser";
import { ErrorLoadingWithDebug } from "../ErrorLoadingWithDebug.js";
import { useComponentState } from "./state.js";
import { ReadyView } from "./views.js";
import { RouteDefinition } from "@gnu-taler/web-util/browser";

export interface Props {
  account: string;
  routeCreateWireTransfer:
    | RouteDefinition<{
        account?: string;
        subject?: string;
        amount?: string;
      }>
    | undefined;
}

export type State = State.Loading | State.LoadingUriError | State.Ready;

export namespace State {
  export interface Loading {
    status: "loading";
    error: undefined;
  }

  export interface LoadingUriError {
    status: "loading-error";
    error: TalerError;
  }

  export interface BaseInfo {
    error: undefined;
  }
  export interface Ready extends BaseInfo {
    status: "ready";
    error: undefined;
    routeCreateWireTransfer:
      | RouteDefinition<{
          account?: string;
          subject?: string;
          amount?: string;
        }>
      | undefined;
    transactions: Transaction[];
    onGoStart?: () => void;
    onGoNext?: () => void;
  }
}

export interface Transaction {
  negative: boolean;
  counterpart: string;
  when: AbsoluteTime;
  amount: AmountJson | undefined;
  subject: string;
}

const viewMapping: utils.StateViewMap<State> = {
  loading: Loading,
  "loading-error": ErrorLoadingWithDebug,
  ready: ReadyView,
};

export const Transactions = utils.compose(
  (p: Props) => useComponentState(p),
  viewMapping,
);
