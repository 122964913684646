/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import { TalerError } from "@gnu-taler/taler-util";
import { Loading, useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { Transactions } from "../components/Transactions/index.js";
import { usePublicAccounts } from "../hooks/account.js";

/**
 * Show histories of public accounts.
 */
export function PublicHistoriesPage(): VNode {
  const { i18n } = useTranslationContext();

  // TODO: implemented filter by account name
  const result = usePublicAccounts(undefined);
  const firstAccount =
    result && !(result instanceof TalerError) && result.body.length > 0
      ? result.body[0].username
      : undefined;

  const [showAccount, setShowAccount] = useState(firstAccount);

  if (!result) {
    return <Loading />;
  }
  if (result instanceof TalerError) {
    return <Loading />;
  }

  const { body: accountList } = result;

  const txs: Record<string, h.JSX.Element> = {};
  const accountsBar = [];

  // Ask story of all the public accounts.
  for (const account of accountList) {
    const isSelected = account.username == showAccount;
    accountsBar.push(
      <li
        class={
          isSelected
            ? "pure-menu-selected pure-menu-item"
            : "pure-menu-item pure-menu"
        }
      >
        <a
          href="#"
          name={`show account ${account.username}`}
          class="pure-menu-link"
          onClick={() => setShowAccount(account.username)}
        >
          {account.username}
        </a>
      </li>,
    );
    txs[account.username] = (
      <Transactions
        account={account.username}
        routeCreateWireTransfer={undefined}
      />
    );
  }

  return (
    <Fragment>
      <h1 class="nav">{i18n.str`History of public accounts`}</h1>
      <section id="main">
        <article>
          <div class="pure-menu pure-menu-horizontal" name="accountMenu">
            <ul class="pure-menu-list">{accountsBar}</ul>
            {typeof showAccount !== "undefined" ? (
              txs[showAccount]
            ) : (
              <p>No public transactions found.</p>
            )}
            <br />
          </div>
        </article>
      </section>
    </Fragment>
  );
}
